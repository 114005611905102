/* Provider / Employees */
.ppl-employee-role .js-employees-card,
.ppl-employee-role .js-auth-rep-card {
    display: none;
}


#smallLoginBox,
#promotionHome,
#newsHome {
    display: none;
}


.pcg-page-wrapper .pcg-jumbotron-title {

    font-size: 2.5rem;
}


.steps-list-container {
    display: flex;
    flex-wrap: wrap;
}

.dbCards {
    width:50%;
  
}

.dbCards a,
.dbCards > div {
    background-color: white;
    display: block;
    margin: 0.5rem;
    transition: all 0.4s ease;
    padding: 2rem 5rem 2rem 2rem;
    position: relative;
    text-decoration: none;
}

.dbCards a i {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.review-card a {
  background-color: white;
  border-left: 5px solid #4f9875;
}

.amber-card a {
  background-color: white;
  border-left: 5px solid #ffb63f;
}

.dbCards h1 {
  font-size: 1.5rem;
  color: #484848;
  text-decoration: none;
  transition: all 0.4s ease;
}

.dbCards .card-btn {
    padding: 0.3rem 1.5rem;
    border-radius: 50px;
    display: inline-block;
}

.dbCards.start-card .card-btn {
  border-color: #8a8a8a;
  color: #484848;
}


.dbCards.review-card .card-btn {
  background-color: #4f9875;
  border-color: #4f9875;
  color: white;
}

.dbCards.continue-card .card-btn {
  background-color: #248FAD;
  border-color: #248FAD;
  color: white;
}

.dbCards.amber-card .card-btn {
  background-color: #ffb63f;
  border-color: #ffb63f;
  color: black;
}


.dbCards.error-card .card-btn {
  background-color: #e6495e;
  border-color: #e6495e;
  color: white;
}


.review-card h1:after {
  content: "Section complete";
  font-size: 0.5em;
    position: absolute;
    right: 1rem;
    top: 70px;
  text-decoration: underline;
}


.steps-list-container a i {
  border-radius: 50%;
  padding: 0.5rem;
  font-size: 1.6rem;
  border: 3px solid white;
  text-align: center;
  display: inline-block;
}

.steps-list-container .continue-card a i {
  color: #248FAD;
  border-color: #248FAD;
}
.steps-list-container .amber-card a i {
  color: #ffb63f;
  border-color: #ffb63f;
}

.steps-list-container .review-card a i {
  color: #4f9875;
  border-color: #4f9875;
}

.pcg-page-wrapper .pcg-homepage-jumbotron {
    background-color: #489afe;
    background-position: -183px bottom;
    background-image: url(/images/banners/betteronline-page.jpg);
    background-size: 1470px;
    padding: 100px 6% 30px 52%;
    min-height: 360px;
}



@media (max-width: 1023.98px) {

    .pcg-page-wrapper .pcg-homepage-jumbotron {
        background-size: 1210px;
        background-image: url(/images/banners/betteronline-page.jpg) !important;
    }

    .pcg-page-wrapper .pcg-homepage-jumbotron__title {
        text-align: left;
    }

    .pcg-page-wrapper .pcg-homepage-jumbotron__list {
        padding-left: 0;
    }

}

@media (max-width: 767.98px) {

    .pcg-page-wrapper .pcg-homepage-jumbotron__list {
        padding-left: 2rem;
    }
    
    .pcg-page-wrapper .pcg-jumbotron-title {
        font-size: 1.75rem;
    }

    .pcg-page-wrapper .pcg-homepage-jumbotron {
        background-image: none !important;
        padding: 2rem;
        display: block;
        min-height: inherit;
    }

    .pcg-page-wrapper .pcg-homepage-jumbotron__title {
        text-align: left;
    }


}